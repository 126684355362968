<template>
  <div class="download">
    <h1>下载页面</h1>
  </div>
</template>
<script>
export default {
  name: 'About',
  data() {
    return {}
  },
}
</script>
<style scoped></style>